var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('validation-observer', {
    ref: "formKaryawan"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Gudang",
      "label-for": "nama_gudang"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nama_gudang",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "nama_gudang",
            "placeholder": "Nama Gudang"
          },
          model: {
            value: _vm.localOptions.nama_gudang,
            callback: function ($$v) {
              _vm.$set(_vm.localOptions, "nama_gudang", $$v);
            },
            expression: "localOptions.nama_gudang"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "alamat",
      "label": "Alamat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "alamat",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "alamat",
            "placeholder": "Masukkan alamat gudang"
          },
          model: {
            value: _vm.localOptions.alamat,
            callback: function ($$v) {
              _vm.$set(_vm.localOptions, "alamat", $$v);
            },
            expression: "localOptions.alamat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "keterangan",
      "label": "Keterangan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "keterangan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "keterangan",
            "placeholder": "Keterangan"
          },
          model: {
            value: _vm.localOptions.keterangan,
            callback: function ($$v) {
              _vm.$set(_vm.localOptions, "keterangan", $$v);
            },
            expression: "localOptions.keterangan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1 mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.update($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.label || 'Simpan Perubahan') + " ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1",
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.resetForm($event);
      }
    }
  }, [_vm._v(" Reset ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }