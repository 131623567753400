<template>
  <!-- <b-card> -->
  <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12" nav-class="nav-left">
    <!-- <b-tabs content-class="pt-1" fill> -->
    <!-- member tab -->
    <b-tab active v-if="isCabang">
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Data Diri</span>
      </template>

      <account-setting-member />
      <!-- v-if="options.member"
        :member-data="options.member" -->
    </b-tab>
    <b-tab v-if="isCabang">
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Ubah Password</span>
      </template>

      <account-setting-passwordmember />
    </b-tab>
    <b-tab active v-if="!isCabang">
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Data Diri</span>
      </template>

      <account-setting-karyawan />
      <!-- v-if="options.karyawan"
        :karyawan-data="options.karyawan" -->
    </b-tab>
    <!--/ member tab -->

    <!-- change password tab -->
    <b-tab v-if="!isCabang">
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Ubah Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab v-if="isFinance || isOwner  || isOwnerHeykama || isCabang || isMarketing">
      <!-- title -->
      <template #title>
        <feather-icon icon="HomeIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Gudang</span>
      </template>

      <account-setting-information />
      <!-- v-if="options.info"
      :information-data="options.info" -->
    </b-tab>

    <!-- rekening -->
    <b-tab v-if="isFinance || isOwner  || isOwnerHeykama || isCabang || isMarketing">
      <!-- title -->
      <template #title>
        <feather-icon icon="CreditCardIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Rekening</span>
      </template>

      <account-setting-rekening />
      <!-- v-if="options.info"
      :information-data="options.info" -->
    </b-tab>
    <!-- alamat -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="MapIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Alamat</span>
      </template>

      <account-setting-alamat />
      <!-- v-if="options.info"
      :information-data="options.info" -->
    </b-tab>

    <!-- social links -->
    <!-- <b-tab>

      title
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Social</span>
      </template>

      <account-setting-social
        v-if="options.social"
        :social-data="options.social"
      />
    </b-tab> -->

    <!-- notification -->
    <!-- <b-tab>

      title
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <account-setting-notification
        v-if="options.notification"
        :notification-data="options.notification"
      />
    </b-tab> -->
  </b-tabs>
<!-- </b-card> -->
</template>

<script>
import { BTabs, BTab,BCard } from "bootstrap-vue";
import AccountSettingMember from "./AccountSettingMember.vue";
import AccountSettingKaryawan from "./AccountSettingKaryawan.vue";
import AccountSettingPassword from "./AccountSettingPassword.vue";
import AccountSettingPasswordmember from "./AccountSettingPasswordMember.vue";
import AccountSettingInformation from "./AccountSettingInformation.vue";
import AccountSettingAlamat from "./AccountSettingAlamat.vue";
import AccountSettingRekening from "./AccountSettingRekening.vue";
import AccountSettingSocial from "./AccountSettingSocial.vue";
import AccountSettingNotification from "./AccountSettingNotification.vue";

export default {
  components: {
    BTabs,
    BTab,BCard,
    AccountSettingMember,
    AccountSettingKaryawan,
    AccountSettingPassword,
    AccountSettingPasswordmember,
    AccountSettingInformation,
    AccountSettingRekening,
    AccountSettingAlamat,
    AccountSettingSocial,
    AccountSettingNotification,
  },
  data() {
    return {
      options: {},
    };
  },
  // beforeCreate() {
  //   this.$http.get("/account-setting/data").then((res) => {
  //     this.options = res.data;
  //   });
  // },
};
</script>
