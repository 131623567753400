<template>
    <b-modal id="foto-modal" no-close-on-backdrop no-close-on-esc>
        <div class="mb-2">
            <img v-if="myAvatar" :src="myAvatar" style="width: 100px;" alt="" class="d-block mb-2" />
            <input type="file" @change="onSelectedFile" />
        </div>
        <template #modal-footer>
            <b-button :disabled="!selectedAvatar" variant="primary" @click.prevent="submit">Simpan</b-button>
        </template>
    </b-modal>    
</template>
<script>
import {BModal, BButton} from 'bootstrap-vue'
export default {
    components: {
        BModal, BButton
    },
    data: () => ({
        selectedAvatar: null,
        myAvatar: null
    }),
    methods: {
        onSelectedFile(e) {
            this.selectedAvatar = e.target.files[0]
            this.myAvatar = URL.createObjectURL(this.selectedAvatar)
        },
        submit() {
            if(!this.selectedAvatar) {
                this.displayError({
                    message: 'Harap pilih file foto!'
                })
                return false
            }

            const fd = new FormData()
            fd.append('file_foto', this.selectedAvatar)

            this.$emit('submitFoto', fd)
        }
    },
    created() {
        if(this.user.karyawan && this.user.karyawan.url_foto) {
            this.myAvatar = this.user.karyawan.url_foto
        }
    }
}
</script>