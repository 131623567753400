var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "foto-modal",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "disabled": !_vm.selectedAvatar,
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_vm.myAvatar ? _c('img', {
    staticClass: "d-block mb-2",
    staticStyle: {
      "width": "100px"
    },
    attrs: {
      "src": _vm.myAvatar,
      "alt": ""
    }
  }) : _vm._e(), _c('input', {
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.onSelectedFile
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }