var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tabs', {
    attrs: {
      "vertical": "",
      "content-class": "col-12 col-md-9 mt-1 mt-md-0",
      "pills": "",
      "nav-wrapper-class": "col-md-3 col-12",
      "nav-class": "nav-left"
    }
  }, [_vm.isCabang ? _c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "UserIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Data Diri")])];
      },
      proxy: true
    }], null, false, 2052206334)
  }, [_c('account-setting-member')], 1) : _vm._e(), _vm.isCabang ? _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "LockIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Ubah Password")])];
      },
      proxy: true
    }], null, false, 3153693379)
  }, [_c('account-setting-passwordmember')], 1) : _vm._e(), !_vm.isCabang ? _c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "UserIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Data Diri")])];
      },
      proxy: true
    }], null, false, 2052206334)
  }, [_c('account-setting-karyawan')], 1) : _vm._e(), !_vm.isCabang ? _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "LockIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Ubah Password")])];
      },
      proxy: true
    }], null, false, 3153693379)
  }, [_c('account-setting-password')], 1) : _vm._e(), _vm.isFinance || _vm.isOwner || _vm.isOwnerHeykama || _vm.isCabang || _vm.isMarketing ? _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "HomeIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Gudang")])];
      },
      proxy: true
    }], null, false, 3977747992)
  }, [_c('account-setting-information')], 1) : _vm._e(), _vm.isFinance || _vm.isOwner || _vm.isOwnerHeykama || _vm.isCabang || _vm.isMarketing ? _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CreditCardIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Rekening")])];
      },
      proxy: true
    }], null, false, 4236405991)
  }, [_c('account-setting-rekening')], 1) : _vm._e(), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "MapIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Alamat")])];
      },
      proxy: true
    }])
  }, [_c('account-setting-alamat')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }