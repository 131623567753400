var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('validation-observer', {
    ref: "formKaryawan"
  }, [_c('b-form', {}, [_c('b-row', [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "cols": "12"
    }
  }, [_vm.myAvatarProfile ? _c('img', {
    staticClass: "d-block rounded mb-2",
    staticStyle: {
      "width": "60px"
    },
    attrs: {
      "src": _vm.myAvatarProfile,
      "alt": ""
    }
  }) : _vm._e(), _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openFotoModal($event);
      }
    }
  }, [_vm._v(" Ubah Foto Profile ")])], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.profile) + " ")]), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Lengkap",
      "label-for": "account-nama_lengkap"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nama Lengkap",
      "name": "nama_lengkap"
    },
    model: {
      value: _vm.profile.nama_lengkap,
      callback: function ($$v) {
        _vm.$set(_vm.profile, "nama_lengkap", $$v);
      },
      expression: "profile.nama_lengkap"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "E-mail",
      "label-for": "account-e-mail"
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.profile.email,
      "name": "email",
      "placeholder": "Email",
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "NIK",
      "label-for": "account-nik"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nik",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": "NIK",
            "name": "nik"
          },
          model: {
            value: _vm.profile.nik,
            callback: function ($$v) {
              _vm.$set(_vm.profile, "nik", $$v);
            },
            expression: "profile.nik"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "NIP",
      "label-for": "account-nip"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "NIP",
      "name": "nip"
    },
    model: {
      value: _vm.profile.nip,
      callback: function ($$v) {
        _vm.$set(_vm.profile, "nip", $$v);
      },
      expression: "profile.nip"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "No HP",
      "label-for": "account-nohp"
    }
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "value": _vm.profile.no_hp,
      "name": "nohp",
      "placeholder": "No HP"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jenis Kelamin",
      "label-for": "account-jk"
    }
  }, [_c('b-form-select', {
    attrs: {
      "name": "jk",
      "placeholder": "Jenis Kelamin",
      "options": [{
        value: 1,
        text: 'Laki-laki'
      }, {
        value: 2,
        text: 'Perempuan'
      }]
    },
    model: {
      value: _vm.profile.jk,
      callback: function ($$v) {
        _vm.$set(_vm.profile, "jk", $$v);
      },
      expression: "profile.jk"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tempat Lahir"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "placeolder": "Ex: Jakarta"
    },
    model: {
      value: _vm.profile.tempat_lahir,
      callback: function ($$v) {
        _vm.$set(_vm.profile, "tempat_lahir", $$v);
      },
      expression: "profile.tempat_lahir"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal Lahir"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date",
      "placeolder": "Ex: 20/20/2022"
    },
    model: {
      value: _vm.profile.tanggal_lahir,
      callback: function ($$v) {
        _vm.$set(_vm.profile, "tanggal_lahir", $$v);
      },
      expression: "profile.tanggal_lahir"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Agama"
    }
  }, [_c('b-form-select', {
    attrs: {
      "name": "agama",
      "options": [{
        value: 1,
        text: 'Islam'
      }, {
        value: 2,
        text: 'Kristen'
      }, {
        value: 3,
        text: 'Katolik'
      }, {
        value: 4,
        text: 'Hindu'
      }, {
        value: 5,
        text: 'Buddha'
      }, {
        value: 6,
        text: 'Khonghuchu'
      }]
    },
    model: {
      value: _vm.profile.agama,
      callback: function ($$v) {
        _vm.$set(_vm.profile, "agama", $$v);
      },
      expression: "profile.agama"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Alamat"
    }
  }, [_c('b-form-textarea', {
    model: {
      value: _vm.profile.alamat,
      callback: function ($$v) {
        _vm.$set(_vm.profile, "alamat", $$v);
      },
      expression: "profile.alamat"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status Perkawinan"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.profile.status_perkawinan,
      callback: function ($$v) {
        _vm.$set(_vm.profile, "status_perkawinan", $$v);
      },
      expression: "profile.status_perkawinan"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kampus Sekolah"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.profile.kampus_sekolah,
      callback: function ($$v) {
        _vm.$set(_vm.profile, "kampus_sekolah", $$v);
      },
      expression: "profile.kampus_sekolah"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pendidikan Terakhir"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.profile.pendidikan_terakhir,
      callback: function ($$v) {
        _vm.$set(_vm.profile, "pendidikan_terakhir", $$v);
      },
      expression: "profile.pendidikan_terakhir"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kualifikasi Pendidikan"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.profile.kualifikasi_pendidikan,
      callback: function ($$v) {
        _vm.$set(_vm.profile, "kualifikasi_pendidikan", $$v);
      },
      expression: "profile.kualifikasi_pendidikan"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Masa Kerja"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.profile.masa_kerja,
      callback: function ($$v) {
        _vm.$set(_vm.profile, "masa_kerja", $$v);
      },
      expression: "profile.masa_kerja"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Grade Karyawan"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.profile.grade_karyawan,
      callback: function ($$v) {
        _vm.$set(_vm.profile, "grade_karyawan", $$v);
      },
      expression: "profile.grade_karyawan"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jabatan"
    }
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "value": _vm.profile.jabatan
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-2 mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit2($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.label || 'Simpan Perubahan') + " ")])], 1)], 1)], 1)], 1), _c('foto-modal', {
    on: {
      "submitFoto": _vm.submitFoto
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }